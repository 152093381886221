"use client";

import { AppRouterCacheProvider } from "@mui/material-nextjs/v13-appRouter";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { DarkModeContextProvider } from "~/context/DarkmodeContext";
import { TrigifyToaster } from "./Toaster";

export default function ThemeRegistry({
  children,
}: {
  children: React.ReactNode;
}) {
  // Update the theme only if the mode changes

  return (
    <AppRouterCacheProvider>
      <DarkModeContextProvider>
        <ProgressBar color="#D5118E" shallowRouting height="4px" options={{ showSpinner: false }} />
        {children}
        <TrigifyToaster />
      </DarkModeContextProvider>
    </AppRouterCacheProvider>
  );
}
