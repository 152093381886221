import { styled } from "@mui/material";
import type { SxProps } from "@mui/material";
import { Loading02 } from "@untitled-ui/icons-react";
import { useDarkMode } from "~/context/DarkmodeContext";

const StyledLoading = styled(Loading02)(({ width, height }) => ({
  animation: "spin 1s linear infinite",
  width,
  height,
}));
export const ButtonSpinner = ({
  width = 14,
  height = 14,
  sx,
}: {
  width?: number;
  height?: number;
  sx?: SxProps;
}) => {
  const { mode } = useDarkMode();
  return <StyledLoading width={width} height={height} mode={mode} sx={sx} />;
};
