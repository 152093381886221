import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/external/gleap.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/external/posthog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/external/product-fruits.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/layout/Main.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/organisations/sync-announcement.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/sidebar/Sidebar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/tables/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/theme/Provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/components/websocket/socket-io-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/hoc/confirmation-dialog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/styles/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/trpc/react.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/ag-grid-community/styles/ag-grid.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}")