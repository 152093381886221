"use client";
import { useCallback, useEffect } from "react";
import { PusherEvents } from "~/types/pusher";
import { successToast } from "../theme/hooks";
import { useEventsWebSocket } from "../websocket/socket-io-provider";

export const SyncAnnouncement = () => {
  const { channel } = useEventsWebSocket();
  const handleSyncComplete = useCallback(() => {
    successToast("CRM sync complete 🎉");
  }, []);
  useEffect(() => {
    channel?.bind(PusherEvents.CRM_SYNC_COMPLETE, handleSyncComplete);
  }, [handleSyncComplete, channel]);
  return null;
};
