import { IconButton } from "@mui/material";
import type { IconButtonProps } from "@mui/material";
import Link from "next/link";

type ActiveLinkProps = IconButtonProps & {
  href: string;
  isActive?: boolean;
  children: React.ReactNode;
};

export const ActiveLink = ({ isActive, href, children, disabled, ...props }: ActiveLinkProps) => {
  return (
    <Link
      href={href}
      style={{
        pointerEvents: disabled ? "none" : "auto",
      }}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
    >
      <IconButton
        disabled={disabled}
        {...props}
        sx={(theme) => ({
          color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
          "&:hover": {
            color: theme.palette.primary.main,
          },
        })}
      >
        {children}
      </IconButton>
    </Link>
  );
};
