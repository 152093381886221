import type { DialogProps } from "@mui/material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { XClose } from "@untitled-ui/icons-react";

export const TrigifyModal = ({
  title,
  open,
  onClose,
  children,
  actions,
  suppressClickaway,
  testId,
  ...rest
}: DialogProps & {
  title: string;
  actions?: React.ReactNode;
  testId?: string;
  suppressClickaway?: boolean;
}) => {
  return (
    <Dialog
      style={{ zIndex: 400 }}
      {...rest}
      data-testid={testId}
      onClose={suppressClickaway ? () => null : onClose}
      open={open}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        sx: (theme) => ({
          "& .MuiPaper-root": {
            [theme.breakpoints.up("md")]: {
              width: 800,
              minWidth: 800,
            },
            [theme.breakpoints.down("md")]: {
              minWidth: 600,
              width: 600,
            },
            [theme.breakpoints.down("sm")]: {
              minWidth: 400,
              width: "100%",
            },
            // minWidth: 600,
            maxHeight: 700,
          },
        }),
      }}
    >
      <DialogTitle
        sx={{
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <IconButton
            onClick={(e) => {
              onClose?.(e, "backdropClick");
            }}
          >
            <XClose />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          background: (theme) => theme.palette.background.default,
          gap: 2,
          p: 3,
        }}
      >
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>{children}</Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          padding: 2,
        }}
      >
        {actions}
      </DialogActions>
    </Dialog>
  );
};
