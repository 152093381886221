"use client";

import posthog from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";

type FeatureFlags =
  | "phone_enrichment"
  | "additional_scripts"
  | "integrations"
  | "url-integration"
  | "organisations"
  | "ai_script"
  | "email_enrichment"
  | "abm_trigger"
  | "hiring_trigger"
  | "list-integrations"
  | "trig_iq"
  | "social_signal"
  | "crm_syncs"
  | "people_enrich"
  | "job_postings"
  | "job_changes"
  | "news_alerts"
  | "automations"
  | "freemium-emails-ab"
  | "wip-integrations";

export const useFeatureFlags = (flag: FeatureFlags) => {
  return useFeatureFlagEnabled(flag);
};

export const getEmailCohort = (flag: FeatureFlags, group: "test_group_1" | "test_group_2") => {
  return posthog.getFeatureFlag(flag) === group;
};
