"use client";
import { UserButton, useOrganization } from "@clerk/nextjs";
import { Box, Tooltip } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { TaskStatus } from "@prisma/client";
import { Home01, Link01, List, Recording01, Settings01, Table } from "@untitled-ui/icons-react";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useDarkMode } from "~/context/DarkmodeContext";
import { generateClerkTheme } from "~/styles/theme";
import { api } from "~/trpc/react";
import { ButtonSpinner } from "../loaders/button-spinner";
import NotificationFeed from "../notifications/notification-center";
import { DarkModeToggle } from "../theme/DarkModeToggle";
import { SIDEBAR_WIDTH } from "./Sidebar.config";
import { navContentGroups } from "./Sidebar.styles";
import { ActiveLink } from "./links/ActiveLink";
import { SlackLink } from "./links/SlackLink";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";
import { useFeatureFlags } from "~/app/hooks/useFeatureFlags";
import { MeteredUsage } from "../credits/metered-usage";
import type { PropsWithChildren } from "react";
import { type KnockProviderProps, KnockProviders } from "../external/knock/providers";

const NavDrawer = ({
  token,
  renderKnock = true,
}: {
  token?: string;
  renderKnock: boolean;
}) => {
  const { organization } = useOrganization();

  const socialsEnabled = useFeatureFlags("social_signal");
  const crmSyncEnabled = useFeatureFlags("crm_syncs");
  const enablementIntegrationEnabled = useFeatureFlags("integrations");
  const { mode } = useDarkMode();
  const pathname = usePathname();

  const { data: linkedInConnection, isLoading: isLoadingConnection } =
    api.integrations.getLinkedInConnectionByOrganisationId.useQuery(
      {
        organisationId: organization?.id!,
      },
      {
        enabled: !!organization?.id,
        refetchOnWindowFocus: false,
      },
    );

  const { data, isLoading } = api.integrations.getIntegrations.useQuery(
    {
      organisationId: organization?.id!,
    },
    {
      enabled: !!organization?.id,
    },
  );

  const { userPlan } = useGetUserPlan();

  const hasSyncedLinkedIn = !!linkedInConnection?.last_synced_posts;

  const firstUserId = linkedInConnection?.li_usernames[0]?.id;

  const isLoadingIntegrations = !!organization?.id && isLoading;
  const firstIntegration = data?.find((integration) => integration.contact_sync_status === TaskStatus.COMPLETE);

  return (
    <Drawer
      sx={{
        width: SIDEBAR_WIDTH,
        flexShrink: 0,
        zIndex: 10,
        "& .MuiDrawer-paper": {
          width: SIDEBAR_WIDTH,
          boxSizing: "border-box",
          justifyContent: "space-between",
        },
        display: "flex",
        flexDirection: "column",
      }}
      variant="persistent"
      anchor="left"
      open
    >
      <Box sx={navContentGroups}>
        <Image src="/logo/logo.svg" alt="trigify" width={32} height={32} />
        <ActiveLink href="/" isActive={pathname === "/"}>
          <Home01 />
        </ActiveLink>

        {socialsEnabled && !isLoadingConnection && (
          <Tooltip
            title={!organization?.id ? "Lets create an organization" : "Social signals"}
            placement="right"
            color="theme.paper"
          >
            <ActiveLink
              href={
                !organization?.id
                  ? "/organisations/create"
                  : hasSyncedLinkedIn
                    ? `/social/${organization.id}/${firstUserId}/signals`
                    : "/social/setup"
              }
              isActive={
                pathname === "/social/setup" || pathname === `/social/${organization?.id}/${firstUserId}/signals`
              }
            >
              <Recording01 />
            </ActiveLink>
          </Tooltip>
        )}

        <ActiveLink
          href={organization?.id ? `/lists/${organization.id}` : "/organisations/create"}
          isActive={pathname.includes("/lists")}
        >
          <List />
        </ActiveLink>

        {userPlan?.subscription_plan !== "FREEMIUM" && (crmSyncEnabled || enablementIntegrationEnabled) && (
          <>
            <Tooltip
              title={!firstIntegration ? "Please integrate a CRM" : "View and manage your contacts"}
              placement="right"
              color="theme.paper"
            >
              <ActiveLink
                disabled={!firstIntegration}
                href={
                  organization?.id
                    ? `/integrations/${organization?.id}/${firstIntegration?.type}/contacts`
                    : "/organisations/create"
                }
                isActive={pathname.includes("integrations")}
              >
                {isLoadingIntegrations ? <ButtonSpinner /> : <Table />}
              </ActiveLink>
            </Tooltip>

            <Tooltip
              title={!organization?.id ? "Lets create an organization" : "View and manage your accounts"}
              placement="right"
              color="theme.paper"
            >
              <ActiveLink
                href={organization?.id ? `/connect/${organization?.id}/accounts` : "/organisations/create"}
                isActive={pathname === `/connect/${organization?.id}/accounts` || pathname === "/organisations/create"}
              >
                <Link01 />
              </ActiveLink>
            </Tooltip>
          </>
        )}
      </Box>
      <Box sx={navContentGroups}>
        <MeteredUsage />
        {/* Notifications only work at an organization level */}
        {token && renderKnock && <NotificationFeed />}
        <SlackLink />
        <ActiveLink href="/settings">
          <Settings01 />
        </ActiveLink>
        <DarkModeToggle />
        <UserButton appearance={generateClerkTheme(mode)} userProfileUrl="/settings" userProfileMode="navigation" />
      </Box>
    </Drawer>
  );
};

export function SidebarInner(props: PropsWithChildren<KnockProviderProps>) {
  const { userId, token } = props;
  const { organization } = useOrganization();

  const pathname = usePathname();
  const onSignInOrUp = pathname.includes("sign-in") || pathname.includes("sign-up") || pathname.includes("onboarding");

  if ((!props.userId || !props.token || !organization?.name) && !onSignInOrUp) {
    return (
      <>
        <NavDrawer renderKnock={false} />
        {props.children}
      </>
    );
  }

  return !onSignInOrUp ? (
    <KnockProviders organisationName={organization?.name} userId={userId} token={token}>
      <NavDrawer token={token} renderKnock />
      {props.children}
    </KnockProviders>
  ) : (
    props.children
  );
}
