export enum PusherEvents {
  HIRING_AUTOMATION_BEGIN = "hiring.automation.begin",
  HIRING_RESULT_FOUND = "hiring.result.found",
  MOVER_AUTOMATION_BEGIN = "mover.automation.begin",
  MOVER_AUTOMATION_END = "mover.automation.end",
  MOVER_AUTOMATION_CANCELLED = "mover.automation.cancelled",
  MOVER_RESULT = "mover.result.created",
  HIRING_AUTOMATION_CANCELLED = "hiring.automation.cancelled",
  HIRING_AUTOMATION_END = "hiring.automation.end",
  HIRING_AUTOMATION_PROGRESS = "hiring.automation.progress",
  ABM_TASK_BEGIN = "abm.task.begin",
  ABM_LIST_BEGIN = "abm.list.begin",
  ABM_LIST_RESULT = "abm.list.result",
  ABM_LIST_END = "abm.list.end",
  ABM_LIST_ERROR = "abm.list.error",
  ABM_TASK_END = "abm.task.end",
  ABM_TASK_ERROR = "abm.task.error",
  ABM_TASK_CANCELLED = "abm.task.cancelled",
  ABM_LEAD_FOUND = "abm.lead.found",
  ABM_EVENT_FOUND = "abm.event.found",
  AI_SCRIPT_FAILURE = "ai.script.failure",
  AI_SCRIPT_CANCELLED = "ai.script.cancelled",
  AI_SCRIPT_STARTED = "ai.script.started",
  AI_SCRIPT_COMPLETE = "ai.script.complete",
  PAYMENT_REQUIRED = "payment.required",
  EMAIL_ENRICHED = "email.enriched",
  PEOPLE_LIST_RESULTS = "people.list.results",
  CORE_SIGNAL_JOB_LIST_BEGIN = "core.signal.job.list.begin",
  CORE_SIGNAL_JOB_LIST_END = "core.signal.job.list.end",
  ATS_JOB_LIST_BEGIN = "ats.job.list.begin",
  ATS_JOB_LIST_END = "ats.job.list.end",
  CORE_SIGNAL_AUTOMATION_ERROR = "core.signal.automation.error",
  ATS_AUTOMATION_ERROR = "ats.automation.error",
  ADD_PEOPLE_TO_JOB_LIST_ERROR = "add.people.to.job.list.error",
  ADD_PEOPLE_TO_JOB_LIST_BEGIN = "add.people.to.job.list.begin",
  ADD_PEOPLE_TO_COMPANY_LIST_BEGIN = "add.people.to.company.list.begin",
  ADD_PEOPLE_TO_JOB_LIST_END = "add.people.to.job.list.end",
  ADD_PEOPLE_TO_COMPANY_LIST_END = "add.people.to.company.list.end",
  PEOPLE_CSV_GENERATED_END = "people.csv.generated.end",
  PEOPLE_CSV_GENERATED_ERROR = "people.csv.generated.error",
  PEOPLE_CSV_GENERATED_BEGIN = "people.csv.generated.begin",
  COMPANY_CSV_GENERATED = "company.csv.generated",
  COMPANY_CSV_GENERATED_ERROR = "company.csv.generated.error",
  ENRICHED_PEOPLE_COMPLETE = "enriched.people.complete",
  ENRICHED_PEOPLE_ERROR = "enriched.people.error",
  ATS_JOB_LIST_ERROR = "ats.job.list.error",
  ALL_JOBS_LIST_ERROR = "all.jobs.list.error",
  CREATE_PEOPLE_LIST_ERROR = "create.people.list.error",
  CREATE_COMPANY_LIST_ERROR = "create.company.list.error",
  PREMIUM_JOBS_LIST_BEGIN = "premium.jobs.list.begin",
  PREMIUM_JOBS_LIST_END = "premium.jobs.list.end",
  EMAIL_ENRICHED_STARTED = "email.enriched.started",
  SYNC_CONTACTS_FAILURE = "sync.contacts.failure",
  CRM_SYNC_COMPLETE = "crm.sync.complete",
  SMARTLEADS_ERROR = "smartleads.error",
  INSTANTLY_ERROR = "instantly.error",
  HEYREACH_ERROR = "heyreach.error",
  LEMLIST_ERROR = "lemlist.error",
  CLAY_ERROR = "clay.error",
  SEED_LINKED_IN_COMPLETE = "seed.linkedin.complete",
  SYNC_LIKES_COMPLETE = "sync.likes.complete",
  SYNC_COMMENTS_COMPLETE = "sync.comments.complete",
}
