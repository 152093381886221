"use client";

import { useUser } from "@clerk/nextjs";
import type { Channel } from "pusher-js";
import { createContext, useContext, useEffect, useState } from "react";
import type { PropsWithChildren } from "react";
import { pusherClient } from "~/services/third-party/pusher";

type WebSocketContextType = {
  channel: Channel | null;
};

export const EventsWebSocketContext = createContext<WebSocketContextType>({
  channel: null,
});

export const useEventsWebSocket = () => {
  if (!EventsWebSocketContext) {
    throw new Error("useEventsWebSocket must be used within a EventsWebSocketProvider");
  }

  return useContext(EventsWebSocketContext);
};

export const EventsWebSocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUser();
  const [channel, setChannel] = useState<Channel | null>(null);

  useEffect(() => {
    if (!user) return;
    if (!channel) {
      const chn = pusherClient.subscribe(`private-${user.id}`);
      setChannel(chn);
      return;
    }
  }, [user, channel]);

  return <EventsWebSocketContext.Provider value={{ channel }}>{children}</EventsWebSocketContext.Provider>;
};
