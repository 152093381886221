"use client";
import { styled } from "@mui/material";
import { SIDEBAR_WIDTH } from "../sidebar/Sidebar.config";

export const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  // marginLeft: `${SIDEBAR_WIDTH}px`,
  maxWidth: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
}));
