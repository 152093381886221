"use client";

import { Button, Divider, TextField, Typography } from "@mui/material";
import { createContext, useCallback, useContext, useState } from "react";
import { LoadingButton } from "~/components/buttons/LoadingButton";
import { TrigifyModal } from "~/components/dialog/dialog";

type ConfirmationDialogProps = Omit<DialogConfig, "actionCallback"> & {
  open: boolean;
  onClose: () => void;
  onConfirm: (inputValue?: string) => Promise<void>;
};

export const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  action = "delete",
  title,
  message,
  showTextField,
  disabled,
  Body,
}: ConfirmationDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm(inputValue);
    onClose();
    setLoading(false);
  };

  return (
    <TrigifyModal
      open={open}
      title={title}
      onClose={onClose}
      maxWidth="lg"
      style={{ zIndex: 1400 }}
      actions={
        <>
          <Button onClick={onClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleConfirm}
            color={action === "create" ? "primary" : "error"}
            autoFocus
            disabled={loading || disabled}
          >
            Confirm
          </LoadingButton>
        </>
      }
    >
      {Body ? <Body /> : <Typography variant="body1">{message}</Typography>}
      {showTextField && (
        <>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <TextField
            value={inputValue}
            onChange={(ev) => setInputValue(ev.target.value)}
            placeholder="Type here..."
            fullWidth={true}
            size="small"
            variant="outlined"
            sx={(theme) => ({
              "& .MuiInputBase-root": {
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: "8px",
                color: theme.palette.text.primary,
              },
            })}
          />
        </>
      )}
    </TrigifyModal>
  );
};

type DialogContext = {
  openDialog: (config: DialogConfig) => void;
};

const ConfirmationDialogContext = createContext<DialogContext>({
  openDialog: () => null,
});

type DialogConfig = {
  title: string;
  message?: string;
  Body?: (() => JSX.Element) | null;
  action?: "create" | "delete";
  disabled?: boolean;
  showTextField: boolean;
  actionCallback: (inputValue?: string) => Promise<void>;
};

export const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = useCallback(
    ({
      message,
      title,
      callback,
      Body,
      disabled,
      action = "delete",
      showTextField = false,
    }: {
      title: string;
      action?: "create" | "delete";
      Body?: (() => JSX.Element) | null;
      message?: string;
      disabled?: boolean;
      callback: (inputValue?: string) => Promise<void> | void;
      showTextField?: boolean;
    }) => {
      return new Promise((res, rej) => {
        openDialog({
          Body,
          actionCallback: async (inputValue = "") => {
            try {
              await callback(inputValue);
              return res(true);
            } catch (error) {
              rej(error);
            }
          },
          title,
          message,
          showTextField,
          action,
          disabled,
        });
      });
    },
    [openDialog],
  );

  return { getConfirmation };
};

export const ConfirmationDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({
    title: "",
    message: "",
    Body: null,
    actionCallback: () => Promise.resolve(),
    showTextField: false,
  });

  const openDialog = useCallback(
    ({
      title,
      message,
      actionCallback,
      action = "delete",
      Body,
      showTextField = false,
      disabled,
    }: {
      action?: "create" | "delete";
      title: string;
      Body?: (() => JSX.Element) | null;
      message?: string;
      actionCallback: () => Promise<void>;
      showTextField: boolean;
      disabled?: boolean;
    }) => {
      setDialogOpen(true);
      setDialogConfig({
        title,
        message,
        actionCallback,
        showTextField,
        action,
        Body,
        disabled,
      });
    },
    [],
  );

  const resetDialog = useCallback(() => {
    setDialogOpen(false);
    setDialogConfig({
      title: "",
      message: "",
      actionCallback: () => Promise.resolve(),
      showTextField: false,
    });
  }, []);

  const onConfirm = useCallback(
    async (inputValue?: string) => {
      resetDialog();
      await dialogConfig.actionCallback(inputValue);
    },
    [dialogConfig, resetDialog],
  );

  const onDismiss = useCallback(() => {
    resetDialog();
  }, [resetDialog]);

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={onDismiss}
        onConfirm={onConfirm}
        action={dialogConfig.action}
        title={dialogConfig.title}
        message={dialogConfig.message}
        Body={dialogConfig.Body}
        disabled={dialogConfig.disabled}
        showTextField={dialogConfig.showTextField}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};
