import type { Theme } from "@mui/material";

export const navContentGroups = (theme: Theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: theme.spacing(2),
  margin: theme.spacing(2),
});
