// app/providers.js
"use client";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: window.location.origin + "/ingest",
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    persistence: "cookie",
  });
}
export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  const { userPlan: data, user } = useGetUserPlan();

  useEffect(() => {
    if (user?.id && data) {
      posthog.identify(user.id, {
        email: user.emailAddresses[0]?.emailAddress,
        name: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
        createdAt: data.date_joined,
        subscription: data.subscription_plan,
        isFreeTrial: data.is_free_trial,
        interval: data.subscription_interval,
        addons: data.subscription_addons?.map((a) => a.name),
      });
    }
  }, [user, data]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
