import toast from "react-hot-toast";

export const successToast = (message: string) => {
  toast.success(message, {
    icon: "🚀",
  });
};

export const errorToast = (
  message: string,
  opts?: {
    duration?: number;
  },
) => {
  toast.error(message, {
    icon: "🚨",
    duration: opts?.duration ?? 10000,
  });
};

export const infoToast = (message: string) => {
  toast(message, {
    icon: "ℹ️",
    duration: 3000,
  });
};

export const promiseToast = <T>(
  cb: Promise<T>,
  {
    successMessage,
    errorMessage,
    loadingMessage,
  }: {
    successMessage: string;
    errorMessage: string;
    loadingMessage: string;
  },
) =>
  toast.promise(
    cb,
    {
      loading: loadingMessage,
      success: successMessage,
      error: errorMessage,
    },
    {
      success: {
        icon: "🚀",
      },
      error: {
        icon: "🚨",
      },
    },
  );
