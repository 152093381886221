import { Button } from "@mui/material";
import type { ButtonProps } from "@mui/material";
import { ButtonSpinner } from "../loaders/button-spinner";

export type LoadingButtonProps = ButtonProps & {
  loading: boolean;
};

export const LoadingButton = (props: LoadingButtonProps) => {
  const { loading, children, ...rest } = props;
  return (
    <Button endIcon={loading ? <ButtonSpinner width={15} /> : rest.endIcon} {...rest}>
      {children}
    </Button>
  );
};
