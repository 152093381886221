import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import type { PaletteMode } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { getPaletteColors, getThemedComponents, trigifyTheme } from "~/styles/theme";

type DarkModeContextType = {
  toggleColorMode: () => void;
  mode: PaletteMode;
};

export const DarkModeContext = createContext<DarkModeContextType>({
  toggleColorMode: () => null,
  mode: "light",
});

export const useDarkMode = () => useContext(DarkModeContext);

const getSSRSafeTheme = () => {
  if (typeof window !== "undefined") {
    const theme = localStorage.getItem("theme");
    if (theme) {
      try {
        return JSON.parse(theme) as PaletteMode;
      } catch (error) {
        return "light";
      }
    }
  }
  return "light";
};

export const DarkModeContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [mode, setMode] = useState<PaletteMode>("light");

  const colorMode = {
    // The dark mode switch would invoke this method
    toggleColorMode: () => {
      setMode((prevMode) => {
        const mode = prevMode === "light" ? "dark" : "light";
        localStorage.setItem("theme", JSON.stringify(mode));
        return mode;
      });
    },
  };

  const theme = createTheme({
    ...trigifyTheme,
    palette: { ...getPaletteColors(mode), mode },
    components: getThemedComponents(mode),
  });

  useEffect(() => {
    const mode = getSSRSafeTheme();
    if (mode) {
      setMode(mode);
      return;
    }
  }, []);

  return (
    <DarkModeContext.Provider
      value={{
        toggleColorMode: colorMode.toggleColorMode,
        mode,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </DarkModeContext.Provider>
  );
};
