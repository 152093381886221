import type { TooltipProps } from "@mui/material";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

export const DynamicTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    borderRadius: theme.sizes.borderRadius.small,
    padding: theme.spacing(2),
  },
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: "large",
    color: theme.palette.background.paper,
  },
}));

export type DynamicPlacementTooltipProps = TooltipProps & {
  children: JSX.Element;
};

export const DynamicPlacementTooltip = ({ title, children, ...others }: DynamicPlacementTooltipProps) => {
  return (
    <DynamicTooltip
      {...others}
      PopperProps={{
        style: {
          zIndex: 1400,
        },
      }}
      leaveDelay={500}
      title={title}
      placement={others.placement ?? "top"}
      arrow
    >
      <div>{children}</div>
    </DynamicTooltip>
  );
};
