"use client";
import { KnockFeedProvider, KnockProvider, KnockSlackProvider } from "@knocklabs/react";
import type { PropsWithChildren } from "react";
import { useDarkMode } from "~/context/DarkmodeContext";

export type KnockProviderProps = {
  userId?: string;
  token?: string;
  organisationName?: string;
};

export const KnockProviders = ({
  children,
  userId,
  token,
  organisationName,
}: PropsWithChildren<KnockProviderProps>) => {
  const { mode } = useDarkMode();

  if (!userId || !token || !organisationName) {
    return <>{children}</>;
  }

  return (
    <KnockProvider apiKey={process.env.NEXT_PUBLIC_KNOCK_PUBLIC_API_KEY!} userId={userId!} userToken={token}>
      <KnockFeedProvider colorMode={mode} feedId={process.env.NEXT_PUBLIC_KNOCK_FEED_CHANNEL_ID!}>
        <KnockSlackProvider
          knockSlackChannelId={process.env.NEXT_PUBLIC_KNOCK_SLACK_CHANNEL_ID!}
          tenant={organisationName}
        >
          {children}
        </KnockSlackProvider>
      </KnockFeedProvider>
    </KnockProvider>
  );
};
