import { Box, LinearProgress, Typography } from "@mui/material";
import { DynamicPlacementTooltip } from "../tooltips/dynamic-tooltip";

export const cleanPercentage = (percentage: number) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

export const CreditCounter = ({
  total,
  remaining,
  title,
  tooltip,
}: {
  total: number;
  title: string;
  tooltip?: string;
  remaining: number;
}) => {
  // get the value from 100% so that the circle gets smaller

  const percentage = cleanPercentage((remaining / total) * 100);

  return (
    <DynamicPlacementTooltip title={tooltip} placement="right">
      <Box
        sx={{
          display: "flex",
          m: 0,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            fontSize: 10,
          })}
          data-testid="credit-counter"
          variant="h5"
        >
          {total === 0 ? "Upgrade" : `${remaining} / ${total}`}
        </Typography>
        <Typography
          sx={{
            m: 0,
            mb: 1,
            textTransform: "uppercase",
            color: (theme) => theme.palette.text.primary,
            fontSize: "0.5rem",
          }}
          variant="caption"
        >
          {title}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            display: "flex",
            width: "100%",
            "& .MuiLinearProgress-root": {
              strokeLinecap: "round",
            },
          }}
        />
      </Box>
    </DynamicPlacementTooltip>
  );
};
