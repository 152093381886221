"use client";

import { IconButton, Tooltip } from "@mui/material";
import { FaSlack } from "react-icons/fa";

export const SlackLink = () => {
  const handleLocalLink = () => {
    window.open(
      `https://join.slack.com/t/trigifyioworkspace/shared_invite/zt-2dqvg0tyt-71m0dvX5sUAGXm~HuMsT~A`,
      "_blank",
    );
  };
  return (
    <Tooltip title="Join us on Slack" placement="right">
      <IconButton onClick={handleLocalLink}>
        <FaSlack />
      </IconButton>
    </Tooltip>
  );
};
