/* eslint-disable @typescript-eslint/no-unsafe-call */
"use client";

import dynamic from "next/dynamic";
import { useEffect, useMemo } from "react";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";

const ProductFruits = dynamic(() => import("react-product-fruits").then((mod) => mod.ProductFruits), { ssr: false });

export const ProductFruitsProvider = () => {
  const { user, userPlan, freeTrialInfo } = useGetUserPlan();

  useEffect(() => {
    if (global.window) {
      // @ts-expect-error - global variable
      window?.productFruits?.pageChanged?.();
    }
  }, []);

  const userInfo = useMemo(
    () =>
      user && userPlan
        ? {
            username: user.id, // REQUIRED - any unique user identifier
            email: user.emailAddresses[0]?.emailAddress!, // REQUIRED - user email
            firstname: user.firstName!, // REQUIRED - user first name
            lastname: user.lastName!, // REQUIRED - user last name
            signUpAt: user.createdAt?.toISOString(), // REQUIRED - user sign up date
            role: userPlan.subscription_plan!, // OPTIONAL - user role
            props: {
              freeTrial: userPlan.is_free_trial ?? false, // OPTIONAL - additional user properties
              plan: userPlan.subscription_plan!, // OPTIONAL - additional user properties
              trialEndsAt: freeTrialInfo?.freeTrialEnd.toISOString(), // OPTIONAL - additional user properties
            }, // OPTIONAL - additional user properties
          }
        : null,
    [user, userPlan, freeTrialInfo?.freeTrialEnd.toISOString],
  );

  return global.window && userInfo && process.env.NODE_ENV !== "development" ? (
    <ProductFruits
      workspaceCode={process.env.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE_CODE!}
      language="en"
      config={{ disableLocationChangeDetection: true }}
      user={userInfo}
    />
  ) : null;
};
