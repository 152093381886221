import { useTheme } from "@mui/material";
import { Toaster } from "react-hot-toast";

export const TrigifyToaster = () => {
  const theme = useTheme();
  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        duration: 5000,
        style: {
          minWidth: "250px",
          maxWidth: "500px",
          padding: "16px",
          border: `2px solid ${theme.gradient}`,
          color: theme.palette.text.primary,
          background: theme.palette.background.default,
        },
      }}
    />
  );
};
