"use client";

import { api } from "~/trpc/react";
import { CreditCounter } from "./credit-counter";
import { Stack } from "@mui/material";
import { ButtonSpinner } from "../loaders/button-spinner";

export const MeteredUsage = () => {
  const { data: email, isLoading: isLoadingEmail } = api.settings.getEmailMeteredUsage.useQuery();
  const { data: phone, isLoading: isLoadingPhone } = api.settings.getPhoneMeteredUsage.useQuery();

  if (isLoadingEmail || isLoadingPhone) {
    return <ButtonSpinner />;
  }

  return (
    <Stack spacing={2}>
      <CreditCounter
        tooltip="Remaining emails for the month"
        title="Emails"
        remaining={email?.data?.aggregated_value ?? 0}
        total={email?.total ?? 0}
      />
      <CreditCounter
        tooltip="Remaining phones for the month"
        title="Phones"
        remaining={phone?.data?.aggregated_value ?? 0}
        total={phone?.total ?? 0}
      />
    </Stack>
  );
};
