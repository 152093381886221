"use client";
import { useOrganization } from "@clerk/nextjs";
import GleapSDK from "gleap";
import { useEffect } from "react";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";

export const Gleap = () => {
  const { organization } = useOrganization();
  const { userPlan, user } = useGetUserPlan();

  useEffect(() => {
    if (!user) {
      return;
    }
    GleapSDK.identify(user.id, {
      name: user.fullName ?? "Unknown",
      email: user.emailAddresses[0]?.emailAddress ?? "",
      phone: user.phoneNumbers[0]?.phoneNumber ?? "",
      plan: userPlan?.subscription_plan ?? "Unknown",
      companyId: organization?.id ?? "Unknown",
      companyName: organization?.name ?? "Unknown",
    });
  }, [user, organization, userPlan]);

  useEffect(() => {
    // Run within useEffect to execute this code on the frontend.
    GleapSDK.initialize(process.env.NEXT_PUBLIC_GLEAP_ID!);
  }, []);

  return null;
};
